import React from 'react'
import { OutboundLink } from 'react-ga'

import { LINKS } from '../constants'
import gameData from '../data/game.json'
import endingsData from '../data/endings.json'
import AnimateGlitch from './AnimateGlitch'
import SocialMedia from './SocialMedia'

export default ({ choices, restartGame }) => {
  let ending = { ...endingsData.default }

  choices.forEach((id, index) => {
    const chapter = gameData.chapters[index]
    const choice = chapter.choices[id]
    const { impact } = choice
    if (impact) {
      Object.keys(impact)
        .forEach(key => {
          const effect = impact[key]
          if (effect === 'increase') {
            ending[key] = ending[key] + 1
          } else if (effect === 'decrease') {
            ending[key] = ending[key] - 1
          } else if (typeof effect === 'number') {
            ending[key] = effect
          }
        })
    }
  })

  Object.keys(ending)
    .forEach(key => {
      if (ending[key] < 0) {
        ending[key] = 0
      }
      if (ending[key] > endingsData.parts[key].length - 1) {
        ending[key] = endingsData.parts[key].length - 1
      }
    })

  const endingTexts = Object.keys(ending).map(key => endingsData.parts[key][ending[key]])
  return (
    <>
      <AnimateGlitch headingLevel={2}>
        {endingsData.title}
      </AnimateGlitch>
      {endingTexts
        .map((text, index) => {
          if (typeof text === 'string') {
            return <p key={`ending-${index}`}>{text}</p>
          }
          return text
            .map((innerText, innerIndex) =>
              <p key={`ending-${index}-${innerIndex}`}>{innerText}</p>
            )
        })
      }
      <p className="question">
        What do you do?
      </p>
      <ul>
        <li>
          <OutboundLink
            eventLabel={LINKS.openPositions}
            to={LINKS.openPositions}
            target="_blank"
          >
            Check out careers at Qvik
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            eventLabel={LINKS.references}
            to={LINKS.references}
            target="_blank"
          >
            See the difference we make at Qvik
          </OutboundLink>
        </li>
        <li>
          <button onClick={restartGame}>
            Play again
          </button>
        </li>
      </ul>
      <SocialMedia />
    </>
  )
}
