export const EVENT_CATEGORIES = {
  game: 'Game'
}

export const EVENT_LABELS = {
  makeChoice: 'Chose option',
  play: 'Started game',
  restart: 'Restarted game',
  setName: 'Set name',
  openRestartDialogue: 'Opened restart dialogue',
  dismissRestartDialogue: 'Closed restart dialogue',
}

export const LINKS = {
  facebook: 'https://www.facebook.com/QvikLtd/',
  instagram: 'https://www.instagram.com/qvik.fi',
  linkedIn: 'https://www.linkedin.com/company/qvik-oy',
  openPositions: 'https://qvik.com/careers/#open_positions',
  qvik: 'https://qvik.com',
  references: 'https://qvik.com/references/'
}
