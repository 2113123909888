import React, { Component } from 'react'
import isRetina from 'is-retina'
import AnimateFade from './AnimateFade'

class AdaptiveImage extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isSmallScreen) {
      const {
        fileName,
        setPreviousImageFileName,
        toggleIsFirstImageActive
      } = this.props
      if (prevProps.fileName !== fileName) {
        setPreviousImageFileName(prevProps.fileName)
        toggleIsFirstImageActive()
      }
    }
  }

  render() {
    const { fileName, isSmallScreen, isFirstImageActive, previousImageFileName } = this.props
    if (fileName) {
      const className = isSmallScreen
        ? 'mobile-ascii-image'
        : 'desktop-ascii-image'
      const image = isRetina()
        ? require(`../data/img/${fileName}@2x.png`)
        : require(`../data/img/${fileName}.png`)
      let previousImageElement = null
      if (previousImageFileName && !isSmallScreen) {
        const previousImage = isRetina()
          ? require(`../data/img/${previousImageFileName}@2x.png`)
          : require(`../data/img/${previousImageFileName}.png`)
        previousImageElement = (
          <img
            src={previousImage}
            className={className}
            alt=""
          />
        )
      }
      const imageElement = <img src={image} className={className} alt="" />
      return (
        <div style={{ position: 'relative' }}>
          {isSmallScreen && imageElement}
          {!isSmallScreen && (
            <>
              <AnimateFade name="image" isVisible={isFirstImageActive}>
                {!isFirstImageActive
                  ? previousImageElement
                  : imageElement
                }
              </AnimateFade>
              <AnimateFade name="image" isVisible={!isFirstImageActive}>
                {isFirstImageActive
                  ? previousImageElement
                  : imageElement
                }
              </AnimateFade>
            </>
          )}
        </div>
      )
    }
    return null
  }
}

export default AdaptiveImage
