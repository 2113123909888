import React from 'react'
import AdaptiveImage from './AdaptiveImage'

export default ({
  currentImageFileName,
  previousImageFileName,
  setPreviousImageFileName,
  isFirstImageActive,
  toggleIsFirstImageActive
}) => (
  <aside className="desktop-chapter-images">
    <AdaptiveImage
      fileName={currentImageFileName}
      previousImageFileName={previousImageFileName}
      setPreviousImageFileName={setPreviousImageFileName}
      isFirstImageActive={isFirstImageActive}
      toggleIsFirstImageActive={toggleIsFirstImageActive}
    />
  </aside>
)
