import React from 'react'
import { OutboundLink } from 'react-ga'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { LINKS } from '../constants'

export default () => (
  <div className="social-media-container">
    <OutboundLink
      eventLabel={LINKS.linkedIn}
      to={LINKS.linkedIn}
      target="_blank"
      className="social-media-link"
    >
      <FontAwesomeIcon className="social-media" icon={fab.faLinkedin} size="lg" />
    </OutboundLink>
    <OutboundLink
      eventLabel={LINKS.instagram}
      to={LINKS.instagram}
      target="_blank"
      className="social-media-link"
    >
      <FontAwesomeIcon className="social-media" icon={fab.faInstagram} size="lg" />
    </OutboundLink>
    <OutboundLink
      eventLabel={LINKS.facebook}
      to={LINKS.facebook}
      target="_blank"
      className="social-media-link"
    >
      <FontAwesomeIcon icon={fab.faFacebook} size="lg" />
    </OutboundLink>
  </div>
)
