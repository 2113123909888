import ReactGA from 'react-ga'
import { __DEV__ } from './state'

export const initializeAnalytics = () => {
  ReactGA.initialize('UA-8899582-8', {
    debug: __DEV__,
    siteSpeedSampleRate: 100
  })
}

export const trackPage = () => {
  if (!__DEV__) {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

export const trackEvent = (options) => {
  if (!__DEV__) {
    ReactGA.event(options)
  }
}