import { useState, useEffect } from 'react'

const LOCAL_DOMAINS = ['localhost', '127.0.0.1']
export const __DEV__ = LOCAL_DOMAINS.includes(window.location.hostname) || !window.location.hostname

const usePersistedState = (key, defaultValue) => {
  const [state, setState] = useState(
    () => localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : defaultValue
  )
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])
  return [state, setState]
}

const initialState = {
  choices: [],
  focusedChapterId: null,
  isLaunched: false,
  isRestartDialogueOpen: false,
  name: null,
  previousImageFileName: null,
  isFirstImageActive: true
}

const keys = {
  choices: 'emet-robotics-choices',
  focusedChapterId: 'emet-robotics-focusedChapterId',
  isLaunched: 'emet-robotics-isLaunched',
  isRestartDialogueOpen: 'emet-robotics-isRestartDialogueOpen',
  name: 'emet-robotics-name',
  previousImageFileName: 'emet-robotics-previousImageFileName',
  isFirstImageActive: 'emet-robotics-isFirstImageActive'
}

// NOTE: Linter disabled only to allow host-based usage of localStorage; functionality is correct.
export const useName = () => {
  const [name, _setName] = __DEV__
    ? useState(initialState.name) // eslint-disable-line react-hooks/rules-of-hooks
    : usePersistedState(keys.name, initialState.name) // eslint-disable-line react-hooks/rules-of-hooks
  return {
    name,
    setName: (newName) => _setName(newName),
    resetName: () => _setName(initialState.name)
  }
}

export const useIsLaunched = () => {
  const [isLaunched, _setIsLaunched] = __DEV__
    ? useState(initialState.isLaunched) // eslint-disable-line react-hooks/rules-of-hooks
    : usePersistedState(keys.isLaunched, initialState.isLaunched) // eslint-disable-line react-hooks/rules-of-hooks
  return {
    isLaunched,
    launch: () => _setIsLaunched(true),
    resetLaunch: () => _setIsLaunched(initialState.isLaunched)
  }
}

export const useRestartDialogue = () => {
  const [isRestartDialogueOpen, _setRestartDialogueOpen] = useState(initialState.isRestartDialogueOpen)
  return {
    isRestartDialogueOpen,
    dismissRestartDialogue: () => _setRestartDialogueOpen(false),
    openRestartDialogue: () => _setRestartDialogueOpen(true)
  }
}

export const useChoices = () => {
  const [choices, _setChoices] = __DEV__
    ? useState(initialState.choices) // eslint-disable-line react-hooks/rules-of-hooks
    : usePersistedState(keys.choices, initialState.choices) // eslint-disable-line react-hooks/rules-of-hooks
  return {
    choices,
    choose: (newChoice) => _setChoices([...choices, newChoice]),
    resetChoices: () => _setChoices(initialState.choices)
  }
}

export const useFocusedChapterId = () => {
  const [focusedChapterId, _setFocusedChapterId] = __DEV__
    ? useState(initialState.focusedChapterId) // eslint-disable-line react-hooks/rules-of-hooks
    : usePersistedState(keys.focusedChapterId, initialState.focusedChapterId) // eslint-disable-line react-hooks/rules-of-hooks
  return {
    focusedChapterId,
    setFocusedChapterId: (chapterId) => _setFocusedChapterId(chapterId),
    resetFocusedChapterId: () => _setFocusedChapterId(initialState.focusedChapterId)
  }
}

export const usePreviousImageFileName = () => {
  const [previousImageFileName, _setPreviousImageFileName] = __DEV__
    ? useState(initialState.previousImageFileName) // eslint-disable-line react-hooks/rules-of-hooks
    : usePersistedState(keys.previousImageFileName, initialState.previousImageFileName) // eslint-disable-line react-hooks/rules-of-hooks
  return {
    previousImageFileName,
    setPreviousImageFileName: (fileName) => _setPreviousImageFileName(fileName),
    resetPreviousImageFileName: () => _setPreviousImageFileName(initialState.previousImageFileName)
  }
}

export const useIsFirstImageActive = () => {
  const [isFirstImageActive, _setIsFirstImageActive] = __DEV__
    ? useState(initialState.isFirstImageActive) // eslint-disable-line react-hooks/rules-of-hooks
    : usePersistedState(keys.isFirstImageActive, initialState.isFirstImageActive) // eslint-disable-line react-hooks/rules-of-hooks
  return {
    isFirstImageActive,
    toggleIsFirstImageActive: () => _setIsFirstImageActive(!isFirstImageActive),
    resetIsFirstImageActive: () => _setIsFirstImageActive(initialState.isFirstImageActive)
  }
}
