import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { trackEvent } from '../analytics'
import { EVENT_CATEGORIES, EVENT_LABELS } from '../constants'

export default ({ openRestartDialogue }) => {
  return (
    <button
      aria-label="restart game"
      className="restart-button"
      onClick={() => {
        trackEvent({
          category: EVENT_CATEGORIES.game,
          action: EVENT_LABELS.openRestartDialogue
        })
        openRestartDialogue()
      }}
    >
      <FontAwesomeIcon icon={fas.faRedo} size="lg" />
    </button>
  )
}
