import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import AnimateFade from './AnimateFade'
import AnimateGlitch from './AnimateGlitch'
import Option from './Option'
import { trackEvent } from '../analytics'
import { EVENT_LABELS } from '../constants'
import AdaptiveImage from './AdaptiveImage'

class Chapter extends React.Component {
  handleAnswer = ({ id, text }) => {
    const { chapter: { id: chapterId } } = this.props
    const { makeChoice } = this.props
    makeChoice(id)
    trackEvent({
      category: chapterId,
      action: EVENT_LABELS.makeChoice,
      label: text,
      value: id
    })
  }

  handleScrollFocus = () => {
    const { chapter, focusedChapterId, setFocusedChapterId } = this.props
    if (
      !focusedChapterId ||
      chapter.id[chapter.id.length - 1] > focusedChapterId[focusedChapterId.length - 1]
    ) {
      setFocusedChapterId(chapter.id)
    }
  }

  render = () => {
    const { name, chapter, choice, isLatestChapter } = this.props
    const paragraphs = chapter.text
      .map((text, index) => (
        <p key={`chapter-${chapter.id}-text-${index}`}>{text.replace('{NAME}', name)}</p>
      ))
    return (
      <VisibilitySensor onChange={this.handleScrollFocus} intervalDelay={600}>
        <>
          {chapter.image &&
            <AnimateFade isAnimatedOnAppear={isLatestChapter}>
              <AdaptiveImage fileName={chapter.image} isSmallScreen />
            </AnimateFade>
          }
          <AnimateFade isAnimatedOnAppear={isLatestChapter}>
            <AnimateGlitch headingLevel={2}>
              {chapter.title}
            </AnimateGlitch>
            {paragraphs}
          </AnimateFade>
          <AnimateFade isAnimatedOnAppear={isLatestChapter} delay={150}>
            <p className="question">{chapter.question}</p>
            <ul>
              {chapter.choices
                .map((option) => (
                  <Option
                    key={`option-${chapter.id}-${option.id}`}
                    option={option}
                    handleAnswer={this.handleAnswer}
                    isAnswered={typeof choice !== 'undefined'}
                    isSelected={option.id === choice}
                  />
                ))
              }
            </ul>
          </AnimateFade>
          {typeof choice !== 'undefined' &&
            <AnimateFade>
              <p className="response">{chapter.choices.find(option => option.id === choice).response}</p>
            </AnimateFade>
          }
        </>
      </VisibilitySensor>
    )
  }
}

export default Chapter
