import React from 'react'

export default ({ children, headingLevel }) => {
  const Container = headingLevel
    ? `h${headingLevel}`
    : 'div'
  return(
    <Container className="glitch">
      {children}
      <span aria-hidden className="glitch-accent">
        {children}
      </span>
    </Container>
  )
}
