import React from 'react'

import {
  useName,
  useIsLaunched,
  useChoices,
  useRestartDialogue,
  useFocusedChapterId,
  useIsFirstImageActive,
  usePreviousImageFileName
} from '../state'
import { EVENT_CATEGORIES, EVENT_LABELS } from '../constants'
import { trackEvent } from '../analytics'
import AnimateFade from './AnimateFade'
import Chapter from './Chapter'
import Ending from './Ending'
import Engage from './Engage'
import Introduction from './Introduction'
import Progress from './Progress'
import RestartDialogue from './RestartDialogue'
import gameData from '../data/game.json'
import ChapterImages from './ChapterImages'

export default () => {
  const { choices, choose, resetChoices } = useChoices()
  const { isLaunched, launch, resetLaunch } = useIsLaunched()
  const { name, setName, resetName } = useName()
  const { focusedChapterId, setFocusedChapterId, resetFocusedChapterId } = useFocusedChapterId()
  const {
    previousImageFileName,
    setPreviousImageFileName,
    resetPreviousImageFileName
  } = usePreviousImageFileName()
  const {
    isFirstImageActive,
    toggleIsFirstImageActive,
    resetIsFirstImageActive
  } = useIsFirstImageActive()
  const {
    isRestartDialogueOpen,
    openRestartDialogue,
    dismissRestartDialogue
  } = useRestartDialogue()

  const launchGame = () => {
    launch()
    trackEvent({
      category: EVENT_CATEGORIES.game,
      action: EVENT_LABELS.play
    })
  }

  const restartGame = () => {
    dismissRestartDialogue()
    resetName()
    resetLaunch()
    resetChoices()
    resetFocusedChapterId()
    resetIsFirstImageActive()
    resetPreviousImageFileName()
    trackEvent({
      category: EVENT_CATEGORIES.game,
      action: EVENT_LABELS.restart
    })
  }

  const Container = isLaunched
    ? 'div'
    : AnimateFade
  const containerProps = isLaunched
    ? { className: 'game' }
    : { isAnimatedOnAppear: true, delay: 300, name: 'game' }

  const currentChapter = [gameData.introduction, ...gameData.chapters]
    .find((c) => c.id === focusedChapterId)
  const currentImageFileName = currentChapter
    ? (currentChapter.image || gameData.chapters[4].image)
    : null

  return (
    <Container {...containerProps}>
      <main>
        <Engage isLaunched={isLaunched} launchGame={launchGame} />
        {isLaunched && (
          <Introduction
            setName={setName}
            isActive={!!name}
            setFocusedChapterId={setFocusedChapterId}
          />
        )}
        <Progress progress={choices.length + (name ? 1 : 0)}/>
        {name &&
          [...Array(choices.length === gameData.chapters.length ? choices.length : choices.length + 1)]
            .map((n, index) => (
              <Chapter
                key={`chapter-${gameData.chapters[index].id}`}
                chapter={gameData.chapters[index]}
                choice={choices[index]}
                isLatestChapter={index === choices.length}
                name={name}
                makeChoice={choose}
                setFocusedChapterId={setFocusedChapterId}
                focusedChapterId={focusedChapterId}
              />
            ))
        }
        {choices.length === gameData.chapters.length &&
          <Ending choices={choices} restartGame={restartGame} />
        }
        <RestartDialogue
          restartGame={restartGame}
          isRestartDialogueOpen={isRestartDialogueOpen}
          dismissRestartDialogue={dismissRestartDialogue}
          openRestartDialogue={openRestartDialogue}
          isRestartButtonVisible={isLaunched && !!name}
        />
      </main>
      <ChapterImages
        currentImageFileName={currentImageFileName}
        previousImageFileName={previousImageFileName}
        setPreviousImageFileName={setPreviousImageFileName}
        isFirstImageActive={isFirstImageActive}
        toggleIsFirstImageActive={toggleIsFirstImageActive}
      />
    </Container>
  )
}
