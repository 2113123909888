import React, { useState, useEffect } from 'react'
import gameData from '../data/game.json'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return { width, height }
}

export default ({ progress }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    const handleResize = () => { setWindowDimensions(getWindowDimensions()) }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []);
  const length = progress
    ? windowDimensions.height / (gameData.chapters.length + 1) * progress
    : 0
  return <div className="progress" style={{ height: length }} />
}
