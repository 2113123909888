import React from 'react'
import { CSSTransition } from 'react-transition-group'

export default ({
  children,
  delay = 0,
  isAnimatedOnAppear = true,
  isVisible = true,
  name = 'fade'
}) => (
  <CSSTransition
    appear={isAnimatedOnAppear}
    classNames={name}
    in={isVisible}
    timeout={delay}
  >
    <div>
      {children}
    </div>
  </CSSTransition>
)
