import React from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

class Option extends React.Component {
  handlePress = () => {
    const { handleAnswer, option: { id, text } } = this.props
    handleAnswer({ id, text })
  }

  render() {
    const { option, isAnswered, isSelected } = this.props
    return (
      <CSSTransition in={!isAnswered} timeout={500} classNames="option">
        <li className={classNames({ 'option-selected': isSelected })}>
          <button
            onClick={this.handlePress}
            disabled={isAnswered}
          >
            {option.text}
          </button>
        </li>
      </CSSTransition>
    )
  }
}

export default Option