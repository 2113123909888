import React from 'react'
import classNames from 'classnames'
import { OutboundLink } from 'react-ga'

import AnimateFade from './AnimateFade'
import AnimateGlitch from './AnimateGlitch'
import AnimateTyping from './AnimateTyping'
import gameData from '../data/game.json'
import { LINKS } from '../constants'

export default ({ isLaunched, launchGame }) => (
  <>
    <AnimateTyping isAnimated={!isLaunched} delay={1000}>
      <AnimateGlitch headingLevel={1}>
        {gameData.engage.title}
      </AnimateGlitch>
    </AnimateTyping>
    <AnimateFade isAnimatedOnAppear={!isLaunched} delay={1800}>
      <p>
        An interactive story by <OutboundLink
          eventLabel={LINKS.qvik}
          to={LINKS.qvik}
          target="_blank"
        >
          Qvik
        </OutboundLink>.
      </p>
      {gameData.engage.text
        .map((text, index) => <p key={`ìntro-engage-text-${index}`}>{text}</p>)
      }
      <p className="question">{gameData.engage.question}</p>
    </AnimateFade>
    <AnimateFade isAnimatedOnAppear={!isLaunched} delay={2000}>
      <div className={classNames({ 'option-selected': isLaunched })}>
        <button
          disabled={isLaunched}
          onClick={launchGame}
        >
          Play to find out
        </button>
      </div>
    </AnimateFade>
  </>
)
