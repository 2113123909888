import React from 'react'

import AnimateFade from './AnimateFade'
import AdaptiveImage from './AdaptiveImage'
import gameData from '../data/game.json'
import { trackEvent } from '../analytics'
import { EVENT_LABELS, EVENT_CATEGORIES } from '../constants'

class Introduction extends React.Component {
  state = {
    inputValue: ''
  }

  componentDidMount() {
    const { setFocusedChapterId } = this.props
    setFocusedChapterId(gameData.introduction.id)
  }

  handleChange = (event) => {
    this.setState({ inputValue: event.target.value })
  }

  handleSubmit = (event) => {
    const { inputValue } = this.state
    const { setName } = this.props
    setName(inputValue)
    trackEvent({
      category: EVENT_CATEGORIES.game,
      action: EVENT_LABELS.setName
    })
    event.preventDefault()
  }

  render = () => {
    const { isActive } = this.props
    const { inputValue } = this.state
    return (
      <AnimateFade isAnimatedOnAppear={!isActive}>
        <AdaptiveImage fileName={gameData.introduction.image} isSmallScreen />
        {gameData.introduction.text
          .map((text, index) => <p key={`ìntro-text-${index}`}>{text}</p>)
        }
        {!isActive &&
          <form onSubmit={this.handleSubmit}>
            <label>
              <p className="question">{gameData.introduction.question}</p>
              <input
                type="text"
                name="name"
                value={inputValue}
                onChange={this.handleChange}
              />
            </label>
            <input
              type="submit"
              value="Begin"
              disabled={!inputValue}
            />
          </form>
        }
        {isActive &&
          <p className="highlight">{inputValue}</p>
        }
      </AnimateFade>
    )
  }
}

export default Introduction
