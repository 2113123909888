import React from 'react'
import Typist from 'react-typist'

export default ({ children, delay = 0, isAnimated = true }) => {
  if (isAnimated) {
    return (
      <Typist cursor={{ show: false }} startDelay={delay} avgTypingDelay={90} stdTypingDelay={20}>
        {children}
      </Typist>
    )
  }
  return children
}
