import React from 'react'
import Game from './components/Game'
import { initializeAnalytics, trackPage } from './analytics'
import './App.css'
import './Fonts.css'

initializeAnalytics()
trackPage()

export default () => <Game />
