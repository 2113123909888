import React, { useEffect } from 'react'
import FocusTrap from 'focus-trap-react'
import ReactDOM from 'react-dom'

import { trackEvent } from '../analytics'
import { EVENT_CATEGORIES, EVENT_LABELS } from '../constants'
import RestartButton from './RestartButton'

const setScrollLock = (isLocked) => {
  if (isLocked) {
    document.querySelector('html').classList.add('lock-scroll')
  } else {
    document.querySelector('html').classList.remove('lock-scroll')
  }
}

export default ({
    restartGame,
    dismissRestartDialogue,
    openRestartDialogue,
    isRestartDialogueOpen,
    isRestartButtonVisible
  }) => {
    useEffect(() => { setScrollLock(isRestartDialogueOpen) }, [isRestartDialogueOpen])
    return (
      <>
        {isRestartButtonVisible &&
          <RestartButton openRestartDialogue={openRestartDialogue} />
        }
        {isRestartDialogueOpen &&
          <>
            <div className="restart-dialogue-shadow" />
            {ReactDOM.createPortal(
              <FocusTrap
                tag="aside"
                aria-modal="true"
                role="dialog"
                tabIndex="-1"
                className="restart-modal"
                aria-labelledby="restart-dialogue-label"
                focusTrapOptions={{
                  onDeactivate: () => {
                    trackEvent({
                      category: EVENT_CATEGORIES.game,
                      action: EVENT_LABELS.dismissRestartDialogue
                    })
                    return dismissRestartDialogue()
                  },
                  clickOutsideDeactivates: true
                }}
              >
                <aside className="restart-dialogue">
                  <p id="restart-dialogue-label">Are you sure you want to restart the game?</p>
                  <button onClick={restartGame}>Restart</button>
                  <button onClick={dismissRestartDialogue}>Cancel</button>
                </aside>
              </FocusTrap>,
              document.body
            )}
          </>
        }
      </>
    )
  }